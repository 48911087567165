import React, { createContext, useState, useEffect } from "react";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {
  // user login
  const [user, setUser] = useState(null);

  // cookie
  const [cookie, setCookie] = useState(false);
  const checkCookie = () => {
    if (document.cookie === "appFormAdmin") {
      setCookie("appFormAdmin");
    }
  };
  useEffect(() => {
    checkCookie();
  }, []);

  // Get all Informations
  const [informationData, setInformationData] = useState([]);

  // Serched Data
  const [serchedData, setSerchedData] = useState(null);

  // Get all Intentions
  const [intentionData, setIntentionData] = useState([]);

  // Get all IntentionsOver
  const [intentionOverData, setIntentionOverData] = useState([]);
  // Get all news
  const [newsData, setNewsData] = useState([]);

  return (
    <StoreContext.Provider
      value={{
        user,
        setUser,
        cookie,
        setCookie,
        informationData,
        setInformationData,
        intentionData,
        setIntentionData,
        intentionOverData,
        setIntentionOverData,
        serchedData,
        setSerchedData,
        newsData,
        setNewsData,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
