import React, { useContext, useState } from "react";
import { StoreContext } from "../../Store/StoreProvider";
import request from "../../helpers/request";

import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/Buttons/Button";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

import styles from "./intentionItem.module.scss";

const IntentionItem = ({ item, intentionOver }) => {
	const { _id, dataFrom, dataTo, intentions, nameOf } = item;

	console.log(intentions);

	const { setIntentionData, setSerchedData, setIntentionOverData } = useContext(StoreContext);

	const [showDetails, setShowDetails] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [addModalOpen, setAddModalOpen] = useState(false);

	const spinner = showSpinner ? <Spinner /> : "";

	const handleConfirmDelete = () => {
		setAddModalOpen(true);
	};

	const handleOnClose = () => {
		setAddModalOpen(false);
	};

	const handleDeleteInformation = async () => {
		setShowSpinner(true);

		if (intentionOver) {
			try {
				const { status } = await request.delete(`/intentionsOver/${_id}`);
				if (status === 200) {
					setIntentionOverData((prev) => prev.filter((item) => item._id !== _id));
					setSerchedData(false);
					setShowSpinner(false);
				}
			} catch (error) {
				console.warn("cos nie taK");
				setShowSpinner(false);
			}
		} else {
			try {
				const { status } = await request.delete(`/intentions/${_id}`);
				if (status === 200) {
					setIntentionData((prev) => prev.filter((item) => item._id !== _id));
					setSerchedData(false);
					setShowSpinner(false);
				}
			} catch (error) {
				console.warn("cos nie taK");
				setShowSpinner(false);
			}
		}
		try {
			const { status } = await request.delete(`/intentions/${_id}`);
			if (status === 200) {
				setIntentionData((prev) => prev.filter((item) => item._id !== _id));
				setSerchedData(false);
				setShowSpinner(false);
			}
		} catch (error) {
			console.warn("cos nie taK");
			setShowSpinner(false);
		}
	};

	const sortedInfo = !intentions ? "" : intentions.split(/\n/);

	const vievInformations = !sortedInfo ? "" : sortedInfo.map((item, index) => <li key={index}>{item}</li>);

	const handleShowDetails = () => {
		setShowDetails(!showDetails);
	};

	const showDetailsButton = !showDetails ? (
		<Button name="szczegóły" onClick={handleShowDetails} />
	) : (
		<Button name="ukryj" onClick={handleShowDetails} />
	);

	const showDetailsInfo = !showDetails ? "" : <ul>{vievInformations}</ul>;
	return (
		<div className={styles.wrapper}>
			<div>
				<p>
					Intencje Mszy Świętych:{" "}
					<span
						style={{
							display: `${intentionOver}?"inline":"none"`,
						}}
					>
						za Śp. {nameOf}
					</span>{" "}
					od {dataFrom} do {dataTo}
				</p>
				<Button name="usuń" onClick={handleConfirmDelete} />
			</div>
			<p>Intencje:</p>
			{showDetailsButton}
			{showDetailsInfo}
			<div className={styles.spinnerWrapper}>{spinner}</div>
			<ConfirmModal
				isModalOpen={addModalOpen}
				handleOnClose={handleOnClose}
				handleDeleteInformation={handleDeleteInformation}
			/>
		</div>
	);
};

export default IntentionItem;
