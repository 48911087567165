import React, { useState, useContext } from "react";
import { StoreContext } from "../../Store/StoreProvider";

import request from "../../helpers/request";
import { addCookie } from "../../helpers/session";

import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/Buttons/Button";

import styles from "./startViev.module.scss";

const StartViev = () => {
  const { setUser, setCookie } = useContext(StoreContext);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [validateMessage, setValidateMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleOnChangeLogin = (event) => setLogin(event.target.value);
  const handleOnChangePassword = (event) => setPassword(event.target.value);

  const resetStateOfInput = () => {
    setLogin("");
    setPassword("");
    setValidateMessage("");
  };

  const spinner = showSpinner ? <Spinner /> : "";

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true);
    const { data, status } = await request.post("/users", {
      login,
      password,
    });

    if (status === 200) {
      setShowSpinner(false);
      setUser(data.user);
      resetStateOfInput();

      addCookie();
      setCookie("appFormAdmin");
    } else {
      setValidateMessage(data.message);
      setShowSpinner(false);
    }
  };

  const validateMessageComponent = validateMessage.length ? (
    <p className={styles.validateMessage}>{validateMessage}</p>
  ) : null;
  return (
    <div className={styles.wrapper}>
      <h2>Strona Logowania</h2>
      <div className={styles.section}>
        <div className={styles.infromation}>{validateMessageComponent}</div>
        <form className={styles.form} method="post" onSubmit={handleOnSubmit}>
          <div className={styles.login}>
            <input
              className={styles.input}
              onChange={handleOnChangeLogin}
              type="text"
              value={login}
              placeholder="Login"
            />
          </div>
          <div className={styles.password}>
            <input
              className={styles.input}
              onChange={handleOnChangePassword}
              type="password"
              value={password}
              placeholder="Hasło"
            />
          </div>
          <div className={styles.button}>
            <Button name="zaloguj" type="submit" />
          </div>
        </form>
        <div className={styles.spinnerWrapper}>{spinner}</div>
      </div>
    </div>
  );
};

export default StartViev;
