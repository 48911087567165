import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import { StoreContext } from "../Store/StoreProvider";

import MainSection from "../templates/MainSection/MainSection";
import Informations from "../templates/Informations/Informations";
import Intentions from "../templates/Intentions/Intentions";
import IntentionsOver from "../templates/IntentionsOver/IntentionsOver";
import News from "../templates/News/News";

function MainSwitch() {
  const { user, cookie } = useContext(StoreContext);
  return (
    <>
      <Switch>
        <Route path="/" exact={true} component={MainSection} />
        {user || cookie ? (
          <Route path="/ogloszenia" render={() => <Informations />} />
        ) : (
          ""
        )}
        {user || cookie ? (
          <Route path="/intencje" render={() => <Intentions />} />
        ) : (
          ""
        )}
        {user || cookie ? (
          <Route
            path="/intencjepozaparafia"
            render={() => <IntentionsOver />}
          />
        ) : (
          ""
        )}
        {user || cookie ? (
          <Route path="/aktualnosci" render={() => <News />} />
        ) : (
          ""
        )}
        {/* <Route component={ErrorPage} /> */}
      </Switch>
    </>
  );
}
export default MainSwitch;
