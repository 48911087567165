import React from "react";

import sytles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={sytles.wrapper}>
      <a href="http://www.developerweb.pl/" target="blanc">
        &copy;developerweb2021
      </a>
      <a href="mailto:tomasz.matras@developerweb.pl">Pomoc</a>
    </div>
  );
};

export default Footer;
