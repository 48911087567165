import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import Modal from "../Modal/Modal";
import Spinner from "../../components/Spinner/Spinner";
import Button from "../Buttons/Button";

import request from "../../helpers/request";

import styles from "./addModalForm.module.scss";

const required = (value) => (value ? undefined : "Pole wymagane");

const AddModalForm = ({
  isModalOpen,
  handleOnClose,
  item,
  handleGetAllNews,
}) => {
  const [showSpinner, setShowSpinner] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const onSubmit = async (values) => {
    setShowSpinner(true);
    const newsObject = {
      id: item._id,
      dataOfAdd: values.dataOfAdd,
      title: values.title,
      informations: values.informations,
    };

    const { data, status } = await request.put("/news", newsObject);
    if (status === 202) {
      handleOnClose();
      handleGetAllNews();
      setShowSpinner(false);
    } else if (status === 409) {
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
      console.log(data.message);
    }
  };

  return (
    <Modal handleOnCloseModal={handleOnClose} isModalOpen={isModalOpen}>
      <div className={styles.wrapper}>
        <h3>Edycja aktualności</h3>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              className={styles.form}
              onSubmit={(event) => {
                const promise = handleSubmit(event);
                promise &&
                  promise.then(() => {
                    form.reset();
                  });
                return promise;
              }}
            >
              <div className={styles.input}>
                <Field name="dataOfAdd" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> data wydarzenia:</label>
                      <input
                        {...input}
                        type="date"
                        placeholder={item.dataOfAdd}
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field name="title" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> Tytuł:</label>
                      <input {...input} type="input" placeholder={item.title} />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
              <div className={styles.notes}>
                <Field name="informations" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> Informacja:</label>
                      <textarea {...input} placeholder={item.informations} />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>

              <div className={styles.buttons}>
                <Button type="submit" name="zapisz" />
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                  className={styles.resetButton}
                >
                  reset
                </button>

                <Button type="button" onClick={handleOnClose} name="wyjdź" />
              </div>
            </form>
          )}
        />
      </div>
      <div className={styles.spinnerWrapper}>{spinner}</div>
    </Modal>
  );
};

export default AddModalForm;
