import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteCoockie } from "../../helpers/session";
import { StoreContext } from "../../Store/StoreProvider";

import Button from "../../components/Buttons/Button";

import styles from "./adminPanel.module.scss";

const AdminPanel = () => {
  let history = useHistory();

  const { setUser, cookie, setCookie } = useContext(StoreContext);

  const handleLogout = () => {
    // if (!user && !cookie) {
    //   setLoginModalOpen(true);
    // }
    setUser(false);
    deleteCoockie(cookie);
    setCookie(false);
    history.push("./");
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        <Link to="/ogloszenia">
          <Button name="Ogłoszenia" />
        </Link>
        <Link to="/intencje">
          <Button name="Intencje" />
        </Link>
        <Link to="/intencjepozaparafia">
          <Button name="Intencje po za" />
        </Link>
        <Link to="/aktualnosci">
          <Button name="Aktualności" />
        </Link>
        <Button onClick={handleLogout} name="wyloguj" />
      </div>
    </div>
  );
};

export default AdminPanel;
