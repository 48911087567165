import React, { useState, useContext } from "react";
import request from "../../helpers/request";
import { StoreContext } from "../../Store/StoreProvider";

import Button from "../../components/Buttons/Button";
import BackButton from "../../components/Buttons/BackButton";
import NewsItem from "../../components/NewsItem/NewsItem";
import Spinner from "../../components/Spinner/Spinner";

import styles from "./news.module.scss";

const News = () => {
  const { newsData, setNewsData } = useContext(StoreContext);

  const [showSpinner, setShowSpinner] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const handleGetAllNews = async () => {
    setShowSpinner(true);
    const { data, status } = await request.get("/news");
    if (status === 200) {
      setShowSpinner(false);
      setNewsData(data.data.reverse());
    } else {
      setShowSpinner(false);
      console.log(status, data.message);
    }
  };

  const news = newsData.map((item) => (
    <NewsItem key={item._id} item={item} handleGetAllNews={handleGetAllNews} />
  ));

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        <Button name="lista aktualności" onClick={handleGetAllNews} />
        <BackButton />
      </div>

      <div className={styles.news}>{news}</div>

      <div className={styles.spinnerWrapper}>{spinner}</div>
    </div>
  );
};

export default News;
