import React, { useState, useContext } from "react";
import request from "../../helpers/request";
import { StoreContext } from "../../Store/StoreProvider";
import { Form, Field } from "react-final-form";

import AddModalForm from "../../components/AddModalForm/AddModalForm";
import Button from "../../components/Buttons/Button";
import BackButton from "../../components/Buttons/BackButton";
import InformationItem from "../../components/InformationItem/InformationItem";
import Spinner from "../../components/Spinner/Spinner";

import styles from "./informations.module.scss";

const Informations = () => {
  const {
    informationData,
    setInformationData,
    serchedData,
    setSerchedData,
  } = useContext(StoreContext);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const handleOnClose = () => {
    setAddModalOpen(false);
  };

  const handleOpenModal = () => {
    setAddModalOpen(true);
  };

  const handleGetAllInformations = async () => {
    setShowSpinner(true);
    const { data, status } = await request.get("/informations");
    if (status === 200) {
      setShowSpinner(false);
      setSerchedData(false);
      setMessage(false);
      setInformationData(data.data.reverse());
    } else {
      setShowSpinner(false);
    }
  };

  const Informations = informationData.map((item) => (
    <InformationItem key={item._id} item={item} />
  ));
  const handleSearchInformation = async (value) => {
    setShowSpinner(true);
    const { data, status } = await request.get(
      `/informations/${String(value.search)}`
    );

    if (status === 200) {
      setSerchedData(data.data.reverse());
      setShowSpinner(false);
    } else {
      setSerchedData(false);
      setShowSpinner(false);
      setMessage(data.message);
    }
  };

  const SarchedInformations = !serchedData ? (
    <p className={styles.message}>{message}</p>
  ) : (
    <InformationItem item={serchedData[0]} />
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        <Button name="dodaj ogłoszenia" onClick={handleOpenModal} />
        <Button name="lista ogłoszeń" onClick={handleGetAllInformations} />
        <div className={styles.search}>
          <Form
            onSubmit={handleSearchInformation}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div>
                  <Field name="search">
                    {({ input, meta }) => (
                      <div>
                        <input {...input} type="date" />
                      </div>
                    )}
                  </Field>
                </div>
                <button type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="black"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  </svg>
                </button>
              </form>
            )}
          />
        </div>
        <BackButton />
      </div>
      <div className={styles.searchedInformations}>{SarchedInformations}</div>
      <div className={styles.informations}>
        {serchedData || message ? "" : Informations}
      </div>
      <AddModalForm isModalOpen={addModalOpen} handleOnClose={handleOnClose} />
      <div className={styles.spinnerWrapper}>{spinner}</div>
    </div>
  );
};

export default Informations;
