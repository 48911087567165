import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreContext } from "../../Store/StoreProvider";

import styles from "./backButton.module.scss";

const BackButton = () => {
  const { setSerchedData } = useContext(StoreContext);

  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
    setSerchedData(false);
  };
  return (
    <button onClick={handleGoBack} className={styles.backButton}>
      wstecz
    </button>
  );
};

export default BackButton;
