import React, { useContext, useState } from "react";
import { StoreContext } from "../../Store/StoreProvider";
import request from "../../helpers/request";

import Spinner from "../../components/Spinner/Spinner";
import Button from "../../components/Buttons/Button";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

import styles from "./informationItem.module.scss";

const InformationItem = ({ item }) => {
  const { _id, dataOfAdd, title, informations } = item;

  const { setInformationData, setSerchedData } = useContext(StoreContext);

  const [showDetails, setShowDetails] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const handleConfirmDelete = () => {
    setAddModalOpen(true);
  };

  const handleOnClose = () => {
    setAddModalOpen(false);
  };

  const handleDeleteInformation = async () => {
    setShowSpinner(true);
    try {
      const { status } = await request.delete(`/informations/${_id}`);
      if (status === 200) {
        setInformationData((prev) => prev.filter((item) => item._id !== _id));
        setSerchedData(false);
        setShowSpinner(false);
      }
    } catch (error) {
      console.warn("cos nie taK");
      setShowSpinner(false);
    }
  };

  const sortedInfo = !informations ? "" : informations.split(/\n/);

  const vievInformations = !sortedInfo
    ? ""
    : sortedInfo.map((item, index) => <li key={index}>{item}</li>);

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const showDetailsButton = !showDetails ? (
    <Button name="szczegóły" onClick={handleShowDetails} />
  ) : (
    <Button name="ukryj" onClick={handleShowDetails} />
  );

  const showDetailsInfo = !showDetails ? "" : <ul>{vievInformations}</ul>;
  return (
    <div className={styles.wrapper}>
      <div>
        <p>
          {title} {dataOfAdd}
        </p>
        <Button name="usuń" onClick={handleConfirmDelete} />
      </div>
      <p>Ogłoszenia:</p>
      {showDetailsButton}
      {showDetailsInfo}
      <div className={styles.spinnerWrapper}>{spinner}</div>
      <ConfirmModal
        isModalOpen={addModalOpen}
        handleOnClose={handleOnClose}
        handleDeleteInformation={handleDeleteInformation}
      />
    </div>
  );
};

export default InformationItem;
