import React from "react";

import sytles from "./header.module.scss";

const Header = () => {
  return (
    <div className={sytles.wrapper}>
      <h1>Zarządzanie stroną Parafii Mokrzyska</h1>
    </div>
  );
};

export default Header;
