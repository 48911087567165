import React, { useContext, useState } from "react";
import { Form, Field } from "react-final-form";
import Modal from "../Modal/Modal";
import Spinner from "../Spinner/Spinner";
import Button from "../Buttons/Button";

import request from "../../helpers/request";
import { StoreContext } from "../../Store/StoreProvider";

import styles from "./addModalForm.module.scss";

const required = (value) => (value ? undefined : "Pole wymagane");

const AddModalFormIntention = ({ isModalOpen, handleOnClose }) => {
  const { setIntentionData, setIntentionOverData } = useContext(StoreContext);

  const [showSpinner, setShowSpinner] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const onSubmit = async (values) => {
    setShowSpinner(true);
    const date = new Date(values.dataFrom);
    const month = date.getMonth();

    const infoObject = {
      nameOf: values.nameOf,
      dataFrom: values.dataFrom,
      dataTo: values.dataTo,
      intentions: values.intentions,
      month: month,
    };
    const { data, status } = await request.post("/intentionsOver", infoObject);
    if (status === 201) {
      handleOnClose();
      setIntentionOverData((prev) => [data.data, ...prev]);

      setShowSpinner(false);
    } else if (status === 409) {
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
      console.log(data.message);
    }
  };

  // useEffect(
  //   (values) => {
  //     if (isModalOpen) {
  //       resetStateOfInput();
  //     }
  //   },
  //   [isModalOpen]
  // );

  return (
    <Modal handleOnCloseModal={handleOnClose} isModalOpen={isModalOpen}>
      <div className={styles.wrapper}>
        <h3>Dodawanie nowych intencji</h3>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              className={styles.form}
              onSubmit={(event) => {
                const promise = handleSubmit(event);
                promise &&
                  promise.then(() => {
                    form.reset();
                  });
                return promise;
              }}
            >
              <div className={styles.input}>
                <div className={styles.nameOf}>
                  <Field name="nameOf" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <label> Za Śp. </label>
                        <input className={styles.name} {...input} />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </div>
                <Field name="dataFrom" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> Tydzeiń od:</label>
                      <input {...input} type="date" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field name="dataTo" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> Tydzeiń do:</label>
                      <input {...input} type="date" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>

              <div className={styles.notes}>
                <Field name="intentions" validate={required}>
                  {({ input, meta }) => (
                    <div>
                      <label> Intencje:</label>
                      <textarea {...input} />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
              <div className={styles.buttons}>
                <Button type="submit" name="zapisz" />
                <button
                  type="button"
                  onClick={form.reset}
                  disabled={submitting || pristine}
                  className={styles.resetButton}
                >
                  reset
                </button>

                <Button type="button" onClick={handleOnClose} name="wyjdź" />
              </div>
            </form>
          )}
        />
      </div>
      <div className={styles.spinnerWrapper}>{spinner}</div>
    </Modal>
  );
};

export default AddModalFormIntention;
