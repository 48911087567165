import React, { useState, useContext } from "react";
import request from "../../helpers/request";
import { StoreContext } from "../../Store/StoreProvider";
import { Form, Field } from "react-final-form";

import AddModalFormIntention from "../../components/AddModalForm/AddModalFormIntention";
import Button from "../../components/Buttons/Button";
import BackButton from "../../components/Buttons/BackButton";
import IntentionItem from "../../components/IntentionItem/IntentionItem";
import Spinner from "../../components/Spinner/Spinner";

import styles from "./intentions.module.scss";

const Intentions = () => {
  const { intentionData, setIntentionData, serchedData, setSerchedData } =
    useContext(StoreContext);

  const [addModalOpen, setAddModalOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState(false);

  const spinner = showSpinner ? <Spinner /> : "";

  const handleOnClose = () => {
    setAddModalOpen(false);
  };

  const handleOpenModal = () => {
    setAddModalOpen(true);
  };

  const handleGetAllIntentions = async () => {
    setShowSpinner(true);
    const { data, status } = await request.get("/intentions");
    if (status === 200) {
      setShowSpinner(false);
      setSerchedData(false);
      setMessage(false);
      setIntentionData(data.data.reverse());
    } else {
      setShowSpinner(false);
    }
  };

  const Intentions = intentionData.map((item) => (
    <IntentionItem key={item._id} item={item} intentionOver={false} />
  ));

  const handleSearchIntentions = async (value) => {
    setShowSpinner(true);
    const { data, status } = await request.get(
      `/intentions/${String(value.search)}`
    );

    if (status === 200) {
      setSerchedData(data.data);
      setShowSpinner(false);
    } else {
      setSerchedData(false);
      setShowSpinner(false);
      setMessage(data.message);
    }
  };

  const SarchedInformations = !serchedData ? (
    <p className={styles.message}>{message}</p>
  ) : (
    <IntentionItem item={serchedData[0]} />
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.buttons}>
        <Button name="dodaj intencje" onClick={handleOpenModal} />
        <Button name="lista intencji" onClick={handleGetAllIntentions} />
        <div className={styles.search}>
          <Form
            onSubmit={handleSearchIntentions}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div>
                  <Field name="search">
                    {({ input, meta }) => (
                      <div>
                        <input {...input} type="date" />
                      </div>
                    )}
                  </Field>
                </div>
                <button type="submit">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="black"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  </svg>
                </button>
              </form>
            )}
          />
        </div>
        <BackButton />
      </div>
      <div className={styles.searchedInformations}>{SarchedInformations}</div>
      <div className={styles.informations}>
        {serchedData || message ? "" : Intentions}
      </div>
      <AddModalFormIntention
        isModalOpen={addModalOpen}
        handleOnClose={handleOnClose}
      />
      <div className={styles.spinnerWrapper}>{spinner}</div>
    </div>
  );
};

export default Intentions;
