import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import StoreProvider from "../src/Store/StoreProvider";

import Header from "./templates/Header/Header.js";
import MainSwitch from "./MainSwitch/MainSwitch";
import Footer from "./templates/Footer/Footer.js";

function App() {
  return (
     <StoreProvider>
      <Router>
        <Header />
        <MainSwitch />
        <Footer />
      </Router>
    </StoreProvider>
  );
}

export default App;
