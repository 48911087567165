import { useContext } from "react";

import StartViev from "../StartViev/StartViev";
import AdminPanel from "../AdminPanel/AdminPanel";

import { StoreContext } from "../../Store/StoreProvider";
import styles from "./mainSection.module.scss";

function MainSection() {
  const { user, cookie } = useContext(StoreContext);

  const mainViev = user || cookie ? <AdminPanel /> : <StartViev />;
  return <div className={styles.wrapper}>{mainViev}</div>;
}

export default MainSection;
