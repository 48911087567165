import React from "react";

import Modal from "../../components/Modal/Modal";
import Button from "../../components/Buttons/Button";

import styles from "./confirmModal.module.scss";

const ConfirmModal = ({
  isModalOpen,
  handleOnClose,
  handleDeleteInformation,
}) => {
  return (
    <Modal isModalOpen={isModalOpen} handleOnClose={handleOnClose}>
      <div className={styles.wrapper}>
        <div className={styles.inside}>
          <h4>Na pewno chcesz usunąć te dane?</h4>
          <Button name="usuń" onClick={handleDeleteInformation} />
          <Button name="wyjdź" onClick={handleOnClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
