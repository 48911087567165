import React, { useState } from "react";

import Button from "../../components/Buttons/Button";
import AddModalFormNews from "../../components/AddModalForm/AddModalFormNews";

import styles from "./newsItem.module.scss";

const NewsItem = ({ item, handleGetAllNews }) => {
  const { dataOfAdd, title, informations } = item;

  const [showDetails, setShowDetails] = useState(false);

  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleOnClose = () => {
    setAddModalOpen(false);
  };

  const handleOpenModal = () => {
    setAddModalOpen(true);
  };

  const sortedInfo = !informations ? "" : informations.split(/\n/);

  const vievNews = !sortedInfo
    ? ""
    : sortedInfo.map((item, index) => <li key={index}>{item}</li>);

  const handleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  const showDetailsButton = !showDetails ? (
    <Button name="szczegóły" onClick={handleShowDetails} />
  ) : (
    <Button name="ukryj" onClick={handleShowDetails} />
  );

  const showDetailsInfo = !showDetails ? "" : <ul>{vievNews}</ul>;
  return (
    <div className={styles.wrapper}>
      <div>
        <p>
          Akktualności: <span>{title}</span>, data <span>{dataOfAdd}</span>
        </p>
        <Button name="zmień" onClick={handleOpenModal} />
      </div>
      <p>Treść:</p>
      {showDetailsButton}
      {showDetailsInfo}

      <AddModalFormNews
        isModalOpen={addModalOpen}
        handleOnClose={handleOnClose}
        item={item}
        handleGetAllNews={handleGetAllNews}
      />
    </div>
  );
};

export default NewsItem;
