import React from "react";

import styles from "./button.module.scss";

function Button({ name, onClick, id }) {
  return (
    <button onClick={onClick} className={styles.smallButton} id={id}>
      {name}
    </button>
  );
}

export default Button;
